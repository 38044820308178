import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPrint,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./PdfViewerPage.css"; // Import custom styles

function PdfViewerPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await axios.post(
          `https://api.gudfynd.com//Order/DownloadCustomerGSTInvoice`,
          { orderID: id }
        );
        console.log(response);
        const base64String = response.data.responseObject;
        const pdfBlob = base64ToBlob(base64String, "application/pdf");
        const pdfUrl = URL.createObjectURL(pdfBlob);

        if (pdfUrl) {
          const link = document.createElement("a");
          link.href = pdfUrl;
          link.download = "document.pdf"; // Customize the file name here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        
        setPdfData({ pdfUrl, pdfBlob });
      } catch (error) {
        console.error("Error fetching PDF data:", error);
        navigate("/error"); // Redirect to error page on error
      }
    };

    fetchPdfData();
  }, [id, navigate]);

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  const handleDownload = () => {
    if (pdfData) {
      const link = document.createElement("a");
      link.href = pdfData.pdfUrl;
      link.download = "document.pdf"; // Customize the file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePrint = () => {
    if (pdfData) {
      const printWindow = window.open(pdfData.pdfUrl);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    }
  };

  const handleShare = () => {
    if (pdfData) {
      navigator
        .share({
          title: "Share PDF Document",
          url: pdfData.pdfUrl,
        })
        .catch((error) => console.error("Error sharing PDF:", error));
    }
  };

  return (
    <div className="pdf-viewer-container">
      {/* <heas */}
      <main className="pdf-viewer-content">
        {pdfData ? (
          <div className="pdf-viewer-wrapper">
            <div className="pdf-controls">
              <button
                className="control-button download-button"
                onClick={handleDownload}
              >
                <FontAwesomeIcon icon={faDownload} /> Download PDF
              </button>
              <button
                className="control-button print-button"
                onClick={handlePrint}
              >
                <FontAwesomeIcon icon={faPrint} /> Print PDF
              </button>
              {/* <button className="control-button share-button" onClick={handleShare}>
                <FontAwesomeIcon icon={faShareAlt} /> Share PDF
              </button> */}
            </div>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer fileUrl={pdfData.pdfUrl} />
            </Worker>
          </div>
        ) : (
          <p>Loading PDF...</p>
        )}
      </main>
    </div>
  );
}

export default PdfViewerPage;
