import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PdfViewerPage from './PdfViewerPage';
import ErrorPage from './ErrorPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/pdf/:id" element={<PdfViewerPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} /> {/* Catch-all route for undefined paths */}
        
      </Routes>
    </Router>
  );
}

export default App;
